export class Search
{
    constructor(toplist)
    {
        this.toplist = toplist;
        this.searchContainer = this.toplist.searchContainer;
        this.searchInput = this.searchContainer.querySelector('input');
        this.toggleBtn = this.searchContainer.querySelector('.evt-open-search');
        this.closeBtn = this.searchContainer.querySelector('.evt-close-search');
        this.timeOut = false;

        this.isVisible = () => {
            return this.searchContainer.classList.contains('open');
        };

        if (!this.toggleBtn && !this.searchInput) {
            console.warn('Toplist search: Cant find required elements');
            return;
        }
        this.toggleBtn.addEventListener('click', () => {
            this.toggleSearch();
        });

        // Search input event
        this.searchInput.addEventListener('input', () => {
            if (this.timeOut) {
                clearTimeout(this.timeOut);
            }

            let str = this.searchInput.value;

            // Use clone of the settings and keep original untouched
            // After closing the search we use original settings to load toplist again
            let settings = JSON.parse(JSON.stringify(this.toplist.settings));

            if (str !== '') {
                if (this.searchContainer.classList.contains('empty')) {
                    this.searchContainer.classList.remove('empty');
                }

                settings.filters = {
                    'SearchFilter': {
                        'name': str
                    }
                };
            } else {
                if (!this.searchContainer.classList.contains('empty')) {
                    this.searchContainer.classList.add('empty');
                }
            }

            // Reset page number
            settings.page = 1;
            this.toplist.settings.page = 1;

            this.timeOut = setTimeout(() => {
                this.toplist.settingsChanged = true;
                this.toplist.fetchToplist(settings);

                if (str !== '') {
                    window.bo.tagManagerTrigger.push({
                        'event': 'toplist--search-' + this.toplist.listType,
                        'eventLabel': str
                    });
                }

                clearTimeout(this.timeOut);
            }, 300);
        });

        if (this.closeBtn) {
            this.closeBtn.addEventListener('click', () => {
                this.close();
            });
        }
    }

    getSearchString()
    {
        let str = this.searchInput.value;
        return str !== '' ? str : null;
    }

    close()
    {
        if (this.searchContainer.classList.contains('open')) {
            this.searchContainer.classList.remove('open');
        }
        if (!this.searchContainer.classList.contains('empty')) {
            this.searchContainer.classList.add('empty');
        }
        if (this.searchInput.value) {
            this.searchInput.value = '';
            this.toplist.settingsChanged = true;

            // Reset page number
            this.toplist.settings.page = 1;
            this.toplist.fetchToplist();
        }
    }

    open()
    {
        if (!this.searchContainer.classList.contains('open')) {
            this.searchContainer.classList.add('open');
            this.searchInput.focus();
        }
    }

    toggleSearch()
    {
        if (this.isVisible()) {
            this.close();
        } else {
            this.open();
        }
    }
}