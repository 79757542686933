export class QuickFilters
{

    constructor(toplist)
    {
        this.toplist = toplist;
        this.container = this.toplist.elm.querySelector('.evt-quick-filters');

        if (!this.container) {
            return;
        }

        // Mobile style, active filter shows on menu toggle button
        this.mobileToggleVisibleValue =
            this.container.querySelector('.open-menu-mobile [data-value]');

        this.desktopMenuButton = this.container.querySelector('.open-menu-desktop');

        // Filter button event
        this.filters = this.container.querySelectorAll('button[data-value]');

        // Toggle dropdown
        this.menu = this.container.querySelector('.menu');
        if (this.menu && this.filters && this.desktopMenuButton) {
            this.bindMenuEvents();
        } else {
            console.warn('Toplist error: Cant initiate quickFilters, missing elements!');
        }
    }

    toggleMenu()
    {

        let _closeListener = (e) => {
            if (e.target.closest('.menu') === null
                && e.target.closest('.evt-open-menu') === null) {
                this.menu.classList.remove('visible');
                window.removeEventListener('click', _closeListener);
            }
        };

        if (!this.menu.classList.contains('visible')) {
            this.menu.classList.add('visible');
            window.addEventListener('click', _closeListener);
        } else {
            this.menu.classList.remove('visible');
            window.removeEventListener('click', _closeListener);
        }
    }

    bindMenuEvents()
    {
        for (let btn of this.container.querySelectorAll('.evt-open-menu')) {
            btn.addEventListener('click', () => {
                this.toggleMenu();
            });
        }

        for (let btn of this.filters) {
            let value = btn.getAttribute('data-value');
            let cta = btn.getAttribute('data-cta');
            if (value && value !== 'custom') {
                btn.addEventListener('click', () => {
                    this.updateViewActiveFilter(value);
                    this.get(value, cta);
                    this.toggleMenu();
                });
            }
        }
    }

    updateViewActiveFilter(value)
    {
        let active = null;
        let activeMenu = false;

        for (let btn of this.filters) {
            if (btn.classList.contains('active') && btn.dataset.value !== value) {
                btn.classList.remove('active');
            } else {
                if (btn.dataset.value === value) {
                    btn.classList.add('active');
                    active = btn;
                    if (!btn.classList.contains('mobile-only') && btn.closest('.menu')) {
                        activeMenu = true;
                    }
                }
            }
        }

        if (this.desktopMenuButton) {
            if ((activeMenu && !this.desktopMenuButton.classList.contains('active')) || value === 'custom') {
                this.desktopMenuButton.classList.add('active');
            } else {
                if (this.desktopMenuButton.classList.contains('active') && !activeMenu) {
                    this.desktopMenuButton.classList.remove('active');
                }
            }
        }

        if (active) {
            this.updateMobileSelectValue(active);
        }
    }


    updateMobileSelectValue(active)
    {
        if (this.mobileToggleVisibleValue) {
            this.mobileToggleVisibleValue.setAttribute('data-value', active.textContent);
        }
    }

    get(alias, cta)
    {
        try {
            // Change settings
            this.toplist.settings = this.toplist.toplistData.quickFilters[alias];

            // Include card status reference
            this.toplist.settings.firstCardOpen = this.container.dataset.firstCardOpen;

            // Use initial list amoutPerPage
            this.toplist.settings.amountPerPage = this.toplist.toplistData.initial.amountPerPage;

            this.toplist.settings.cta = cta;

            if(this.toplist.toplistSearch) {
                this.toplist.toplistSearch.close(false);
            }

            this.toplist.settingsChanged = true;
            this.toplist.fetchToplist();
        } catch (e) {
            console.log(e);
        }
    }
}