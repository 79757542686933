import {Search} from './Search.js';
import {QuickFilters} from './QuickFilters.js';

export class Toplist
{
    constructor(elm)
    {
        if (!(elm instanceof HTMLElement)) {
            return;
        }

        this.elm = elm;
        this.settings = null;
        this.loader = null;
        this.ajax = null;
        this.listType = elm.hasAttribute('data-type') ? elm.getAttribute('data-type') : '';

        this.openModalBtns = this.elm.querySelectorAll('button[data-url].evt-open-toplist-modal');
        this.toplistContent = this.elm.querySelector('.toplist-content');
        this.cardContainer = this.elm.querySelector('.toplist-content .cards');
        this.settingsChanged = false;
        this.totalMatched = null;
        this.totalAmount = null;
        this.amountLeft = null;
        this.isBackend = false;
        this.loadUrl = this.elm.hasAttribute('data-load-url') ? this.elm.getAttribute(
            'data-load-url') : '/api/toplist/load/';
        this.toplistData = {};

        // Classes used to control dom
        this.class = {
            'loading': 'loading',
            'edit': 'edit',
            'active': 'active',
            'visible': 'visible',
            'centered': 'centered',
            'open': 'open',
            'empty': 'empty'
        };

        if (!this.toplistContent) {
            console.error('Toplist: Error, toplist content elm not found!');
            return;
        }

        // Toplist settings
        if (this.elm.querySelector('script')) {
            this.toplistData = JSON.parse(this.elm.querySelector('script').innerHTML);
        } else {
            console.error('Toplist: Error, settings JSON is missing!');
            return;
        }

        // Use initial settings if not selected any
        if (!this.settings && this.toplistData.initial) {
            this.settings = JSON.parse(JSON.stringify(this.toplistData.initial));
        }

        if (!this.settings) {
            console.error('Missing toplist settings, cant initialize toplist');
            return;
        }

        // Just in case
        if (this.settings && !('amountPerPage' in this.settings)) {
            this.settings['amountPerPage'] = 10;
        }

        this.bindEvents();
    }

    async bindEvents()
    {
        // Open toplist moddal
        for (let btn of this.openModalBtns) {
            let url = btn.dataset.url;
            btn.addEventListener('click', () => {
                this.openModal(url, btn.dataset.matched, btn.dataset.total);
            });
        }

        // Init backend functions
        this.saveToplist = this.elm.querySelector('button.save-toplist');
        if (this.saveToplist && this.saveToplist.hasAttribute('data-save-url')) {
            this.isBackend = true;
            this.additionalSorters = document.querySelectorAll('input.additional-sorter, select.additional-sorter');
            const {BackendFunctions} = await import('./BackendFunctions.js');
            this.backEndFn = new BackendFunctions(this);
        }

        // Toplist search
        this.searchContainer = this.elm.querySelector('.toplist-search');
        this.toplistSearch = null;
        if (this.searchContainer) {
            this.toplistSearch = new Search(this);
        }

        // Load more cards
        this.loadMoreBtn = this.elm.querySelector('.evt-load-more-casinos');
        if (this.loadMoreBtn) {
            this.loadMoreBtn.addEventListener('click', () => {
                this.settings.page = this.settings.page + 1;
                this.settingsChanged = true;

                let settings = JSON.parse(JSON.stringify(this.settings));

                // If search active
                if (this.toplistSearch) {
                    let str = this.toplistSearch.getSearchString();
                    if (str) {
                        settings.filters = {
                            'SearchFilter': {
                                'name': str
                            }
                        };
                    }
                }

                this.fetchToplist(settings, true);
            });
        }

        // Quick filters
        if (!this.isBackend) {
            this.quickFilters = new QuickFilters(this);
        }
    }

    // Scrolls view back to top of the list
    scrollViewIfNeeded()
    {
        let toplist = this.elm;
        let headerH = document.querySelector('header').offsetHeight;
        if (toplist.getBoundingClientRect().top < headerH) {
            window.scrollTo(window.pageXOffset, toplist.offsetTop - headerH - 15);
        }
    }

    applySorting(key, dir = false)
    {
        if (key === 'order') {
            this.applyValueToSettings(this.toplistData.initial.sorters, 'sorters');
        } else {
            let data = {};
            data[key] = {
                order: dir
            };

            this.applyValueToSettings(data, 'sorters');
        }

        this.fetchToplist();
    }

    applyValueToSettings(data, parent = false)
    {
        let lastSettings = JSON.stringify(this.settings);

        try {
            if (parent) {
                if (!this.settings[parent]) {
                    this.settings[parent] = Object.assign({}, data);
                } else {
                    this.settings[parent] = Object.assign(this.settings[parent], data);
                }
            } else {
                this.settings = Object.assign(this.settings, data);
            }
        } catch (err) {
            console.error('Toplist modal:' + err);
        }

        // Reset search status if filters change
        if (this.toplistSearch) {
            this.toplistSearch.close();
        }

        if (lastSettings !== JSON.stringify(this.settings)) {
            this.settingsChanged = true;
            if (this.quickFilters) {
                this.quickFilters.updateViewActiveFilter('custom');
                this.settings.cta = null;
            }
        }
    }

    discardValueFromSettings(key, parent = false)
    {
        let lastSettings = JSON.stringify(this.settings);

        try {
            if (parent) {
                delete this.settings[parent][key];
            } else {
                delete this.settings[key];
            }
        } catch (err) {
            console.error('Toplist modal:' + err);
        }

        // Reset search status if filters change
        if (this.toplistSearch) {
            this.toplistSearch.close();
        }

        if (lastSettings !== JSON.stringify(this.settings)) {
            this.settingsChanged = true;
        }
    }

    // Backend
    handleAdditionalSorters()
    {
        for (let sorter of this.additionalSorters) {
            let value = sorter.value;
            let data = {};

            if (value) {
                data[value] = {
                    'order': sorter.getAttribute('data-order')
                };
                this.applyValueToSettings(data, 'sorters');
            } else {
                if (value === '') {
                    this.discardValueFromSettings(sorter.previousValue, 'sorters');
                }
            }
        }
    }

    fetchToplist(settings = this.settings, append = false)
    {
        if (!this.settingsChanged) {
            return;
        }

        this.setLoader();

        if (this.ajax) {
            this.ajax.getXhrObject().abort();
            this.ajax = null;
        }

        if (this.isBackend) {
            this.handleAdditionalSorters();
        }

        this.ajax = window.bo.ajax.post(this.loadUrl,
            JSON.stringify(settings),
            {'contentType': 'application/json'}).onLoad(xhr => {

            this.clearLoader();

            try {
                let response = JSON.parse(xhr.responseText);
                if (response.message !== null) {
                    if (!append) {
                        this.cardContainer.innerHTML = response.data.output;
                        this.scrollViewIfNeeded();
                    } else {
                        this.cardContainer.innerHTML += response.data.output;
                    }
                }

                this.settingsChanged = false;
                this.amountLeft = response.data.amountLeft;
                this.totalMatched = response.data.totalMatched;
                this.totalAmount = response.data.totalAmount;
                window.bo.events.bindAll(this.cardContainer);

                // Empty set
                if (!this.totalMatched && !this.cardContainer.classList.contains(this.class.empty)) {
                    this.cardContainer.classList.add(this.class.empty);
                } else {
                    if (this.totalMatched && this.cardContainer.classList.contains(this.class.empty)) {
                        this.cardContainer.classList.remove(this.class.empty);
                    }
                }

                // Hide load if no results left / found
                if (this.loadMoreBtn &&
                    this.loadMoreBtn.hidden &&
                    response.data.amountLeft > 0) {
                    this.loadMoreBtn.hidden = false;
                } else {
                    if (this.loadMoreBtn &&
                        !this.loadMoreBtn.hidden &&
                        response.data.amountLeft <= 0) {
                        this.loadMoreBtn.hidden = true;
                    }
                }

                return true;
            } catch (e) {
                // Invalid response, not JSON data
                console.warn(e);
                if (xhr.responseText.length === 0) {
                    window.bo.toast.warning(window.messages.emptyResponseFromApi);
                } else {
                    window.bo.toast.warning(window.messages.invalidResponseFromApi);
                }
                return false;
            }
        }).onError(() => {
            this.clearLoader();
            return false;
        }).onTimeout(() => {
            this.clearLoader();
            return false;
        }).onEnd(() => {
            this.ajax = null;
        });
    }

    async openModal(url, matched, total)
    {
        if (!this.elm.classList.contains('modal-open')) {
            this.elm.classList.add('modal-open');
        }

        if (!this.totalAmount) {
            this.totalAmount = total;
            this.totalMatched = matched;
        }

        const {modal} = await import('../../Library/Modal.js');

        modal.open({
            modalClass: 'toplist-modal',
            customTemplate: true,
            contentAjaxUrl: url,
            onClose: () => {
                if (this.elm.classList.contains('modal-open')) {
                    this.elm.classList.remove('modal-open');
                }
                this.fetchToplist();
                this.scrollViewIfNeeded();
            },
            onOpen: (modal) => {
                import('./Modal/Modal.js').then(({Modal}) => {
                   new Modal(modal.elm, this);
                });
            }
        });
    }

    setLoader()
    {
        this.loader = setTimeout(() => {
            if (!this.elm.classList.contains(this.class.loading)) {
                this.elm.classList.add(this.class.loading);

                let overlay = document.createElement('div');
                overlay.classList.add('overlay');
                this.toplistContent.appendChild(overlay);
                window.bo.loaderElement.appendTo(overlay);
            }
        }, 100);
    }

    clearLoader()
    {
        clearTimeout(this.loader);
        this.loader = null;
        this.elm.classList.remove(this.class.loading);
        let overlay = this.toplistContent.querySelector('.overlay');
        if (overlay) {
            overlay.remove();
        }
    }
}